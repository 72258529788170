.privacy-policy h3 {
    font-size: 1.17em;
    margin: 1em 0;
    display: block;
    font-weight: 700;
}
.privacy-policy h1 {
    font-size: 2em;
    margin: .67em 0;
}

.privacy-policy h1,.privacy-policy h2 {
    display: block;
    font-weight: 700;
}

.privacy-policy h2 {
    font-size: 1.5em;
    margin: .83em 0
}
